<template>
  <div class="mb-100">
    <div class="divider-full"></div>
    <div class="container-fluid section-head section-tour">
      <div class="container-standar container-res-1440 sub-tour">
        <span class="pt-6 pb-6 text-white font-weight-6 font-24 d-inline-block text-with">Tour</span>
      </div>
    </div>
    <div class="mt-6 container-standar">
      <div class="row">
        <div class="col-md-6">
          <span class="font-weight-6 text-primary">
            Result:
            <span class="text-dark">Domestic</span>
          </span>
        </div>
      </div>
    </div>
    <div class="container-standar mt-10">
      <div class="row">
        <div class="col-md-3 w-res-tab-small-flex-50 mb-10 w-res-flex-100" :key="index" v-for="(items, index) in dataTour">
          <div class="card shadow h-100 card-for-tour cursor-pointer" @click="changePage(items)">
            <div class="tour-card"  >
                <template v-if="items.images == 0">
                  <img src="@/assets/images/background/tour-1.jpg" alt="">
                </template>
                <template v-else >
                   <div  >
                    <img v-bind:src="chesen(items)" alt />
                  </div>
                </template>  
              <span class="labelTour font-16 font-weight-6 text-white">{{ items.travel }}</span>
              <div class="overlay-more">
                <span
                  class="text-white font-weight-6 text-center font-20 margin-auto w-100 d-block"
                >View Detail</span>
              </div>
            </div>
            <div class="title pad-10">
              <span
                class="d-block w-100 font-weight-6"
              >{{ items.nama }}</span>
              <span class="d-block w-100 font-14 mt-2 text-warning font-weight-6">Starting/ Person</span>
              <span class="d-block w-100 font-20 text-danger font-weight-7">IDR {{ items.harga | numeralFormat('0,0[.]00') }}</span>
            </div>
          </div>
        </div>
        
      </div>
      <template v-if="numberCount < totalCount">
         <div class="col-md-12 text-center justify-center mt-10" >
          <span @click.prevent="countMethod()" class=" d-block width-20 w-res-100 w-res-tab-small-80   margin-auto text-center border-radius-100 bg-warning cursor-pointer text-white  pad-10 font-18 font-weight-6">Show More</span>
        </div>
      </template>
      
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dataTour:[],
      dataImages:{},
      dataPagging:[],
      numberCount: 8,
      showCount:null,
      totalCount:null,
      parse:null
    };
  },
  mounted() {
    this.$store.commit("SET_BG", false);
    this.dataparse =  JSON.parse(localStorage.getItem('response-tour'))
     this.parseMethod()
    this.totalCount = this.dataparse.data.length
    //  console.log(this.dataTour.data)
 
    //  this.dataImages.push(element.images.file)
        // let ab = this.dataTour.data
        //  ab.forEach(element => {
        //    console.log(element.images[0])
        //  });
      //  var result = Object.keys(ab).map(function(key) {
      //     return [Number(key), ab[key]];
      //   });
        // console.log(result)
        //  return element[0]
   
  },
  methods: {
    parseMethod(){
        this.dataTour = this.dataparse.data.slice(0, this.numberCount)
    },
    countMethod(){
      this.numberCount = this.numberCount + 8
        this.parseMethod()
       
    },
    chesen(val){
      return val.images[0].file
      // return val[0]
    },
    changePage(NewVal){
      this.$router.push('/tour/'+NewVal.slug)
    }
  },
};
</script>